import React, { useState } from "react";
import "./App.css";
import {
  ScaledWrapper,
  RemoteSpiIndicator,
  FontSizeJig,
  BackgroundTts,
} from "./components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";

import { Provider } from "react-redux";
import { rootReducer } from "./reducers";
import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./sagas";
const sagaMiddleware = createSagaMiddleware();
const composeEnhancer = composeWithDevTools({ trace: true, traceLimit: 25 });
const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

interface ReadyWrapperProps {
  isReady: boolean;
  setReady: (ready: boolean) => void;
  children: any;
}
const ReadyWrapper: React.FC<ReadyWrapperProps> = ({
  isReady,
  setReady,
  children,
}) => {
  return isReady ? (
    children
  ) : (
    <div className="screen-ready" onClick={() => setReady(true)}>
      <div>Click anywhere to begin</div>
    </div>
  );
};

const App: React.FC = () => {
  const [isReady, setReady] = useState(true);

  return (
    <Provider store={store}>
      <BackgroundTts />
      <Router>
        <Switch>
          <Route path="/remote/:screenUuid">
            <ReadyWrapper isReady={isReady} setReady={setReady}>
              <ScaledWrapper height={1080} width={1920}>
                <RemoteSpiIndicator />
              </ScaledWrapper>
            </ReadyWrapper>
          </Route>
          <Route path="/jig">
            <FontSizeJig />
          </Route>
          {/* <Route path="/pid/split-screen-landscape/:region/:stopIds">
            <ReadyWrapper isReady={isReady} setReady={setReady}>
              <ScaledWrapper height={1080} width={1920}>
                <SpiIndicator mode="split-screen-landscape" />
              </ScaledWrapper>
            </ReadyWrapper>
          </Route>
          <Route path="/pid/alt-services-landscape/:region/:stopIds">
            <ReadyWrapper isReady={isReady} setReady={setReady}>
              <ScaledWrapper height={1080} width={1920}>
                <SpiIndicator mode="alt-services-landscape" />
              </ScaledWrapper>
            </ReadyWrapper>
          </Route>
          <Route path="/pid/dual-screen-landscape/:region/:stopIds">
            <ReadyWrapper isReady={isReady} setReady={setReady}>
              <ScaledWrapper height={1080} width={3840}>
                <SpiIndicator mode="dual-screen-landscape" />
              </ScaledWrapper>
            </ReadyWrapper>
          </Route>
          <Route path="/pid/single-screen-landscape/:region/:stopIds">
            <ReadyWrapper isReady={isReady} setReady={setReady}>
              <ScaledWrapper height={1080} width={1920}>
                <SpiIndicator mode="single-screen-landscape" />
              </ScaledWrapper>
            </ReadyWrapper>
          </Route>
          <Route path="/pid/precinct-services-landscape/:region/:stopIds">
            <ReadyWrapper isReady={isReady} setReady={setReady}>
              <ScaledWrapper height={1080} width={1920}>
                <SpiIndicator mode="precinct-services-landscape" />
              </ScaledWrapper>
            </ReadyWrapper>
          </Route>
          <Route path="/pid/waiting-room-landscape/:region/:stopIds">
            <ReadyWrapper isReady={isReady} setReady={setReady}>
              <ScaledWrapper height={1080} width={1920}>
                <SpiIndicator mode="waiting-room-landscape" />
              </ScaledWrapper>
            </ReadyWrapper>
          </Route> */}
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
