import React from 'react';
import Moment from 'react-moment';
import { useAs1428 } from '../hooks/useAs1428';

interface PlrModeHeaderProps {
    title: string;
    modeIcon?: string;
    subtitle?: string;
    currentTime?: boolean;
}
export const PlrModeHeader: React.FC<PlrModeHeaderProps> = ({title, modeIcon, subtitle, currentTime}) => {
    const {getDimension} = useAs1428(55);
    return <div className="plr-header" style={{height: getDimension(165), paddingLeft: getDimension(50), paddingRight: getDimension(50)}}>
        <div className="plr-header-wrapper">
            <div className="plr-header-left">
                {modeIcon && <div className="plr-header-mode-icon" style={{height: getDimension(110), width: getDimension(110), marginTop: getDimension(27.5), marginRight: getDimension(50)}}>
                    <img src={modeIcon} style={{width: '100%', height: '100%'}} alt="Mode icon" />
                </div>}
                <div className="plr-header-mode-description" style={{paddingTop: getDimension(35), paddingBottom: getDimension(40)}}>
                    <div className="plr-header-mode-name" style={{lineHeight: getDimension(45, 'px'), fontSize: getDimension(60)}}>{title}</div>
                    {subtitle && <div className="plr-header-mode-location" style={{lineHeight: getDimension(20, 'px'), fontSize: getDimension(27)}}>{subtitle}</div>}
                </div>
            </div>
            {currentTime ? <div className="plr-time-now" style={{marginTop: getDimension(35), marginBottom: getDimension(40)}}>
                <div className="plr-time-now-label" style={{lineHeight: getDimension(20, 'px'), fontSize: getDimension(27)}}>Time now</div>
                <div className="plr-time-now-time" style={{lineHeight: getDimension(45, 'px'), fontSize: getDimension(60)}}><Moment format="HH:mm" interval={1000} /></div>
            </div> : null}
        </div>
    </div>
}