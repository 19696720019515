import React from 'react';
import Moment from 'react-moment';
import { useAs1428 } from '../hooks/useAs1428';

interface PlrAltHeaderProps {
    title: string;
    modeIcon?: string;
    currentTime?: boolean;
}
export const PlrAltHeader: React.FC<PlrAltHeaderProps> = ({title, currentTime}) => {
    const {getDimension} = useAs1428(55);
    return <div className="plr-header" style={{height: getDimension(165), paddingLeft: getDimension(50), paddingRight: getDimension(50)}}>
        <div className="plr-header-wrapper">
            <div className="plr-header-left">
                <div className="plr-header-mode-description" style={{paddingTop: getDimension(35), paddingBottom: getDimension(40), justifyContent: 'center'}}>
                    <div className="plr-header-mode-name" style={{lineHeight: getDimension(45, 'px'), fontSize: getDimension(60)}}>{title}</div>
                </div>
            </div>
            {currentTime ? <div className="plr-time-now" style={{marginTop: getDimension(35), marginBottom: getDimension(40)}}>
                <div className="plr-time-now-label" style={{lineHeight: getDimension(20, 'px'), fontSize: getDimension(27)}}>Time now</div>
                <div className="plr-time-now-time" style={{lineHeight: getDimension(45, 'px'), fontSize: getDimension(60)}}><Moment format="HH:mm" interval={1000} /></div>
            </div> : null}
        </div>
    </div>
}