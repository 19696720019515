import React, { useEffect, useRef, useState } from "react"
import ReactSound from "react-sound"
import { useDispatch, useSelector } from "react-redux"
import { actions } from "../../reducers";
import { useInterval } from "../SpiIndicator/hooks/useInterval";

export const BackgroundTts : React.FC = () => {
    const dispatch = useDispatch();
    const [ttsUrlMemory, setTtsUrlMemory] = useState<string>();
    const [playStatus, setPlayStatus] = useState<any>();
    // const ttsUrl = useSelector((state: any) => state.tts.ttsUrl);
    const {audioEnabled, audioMaxFrequency, audioWaitBeforeRepeating, ttsUrl} = useSelector((state: any) => state.tts);

    // const ttsUrlMemory = useRef<string>();
    const lastPlayed = useRef<number>();
    const lastPlayedByUrl = useRef<{[k: string]: number}>({});

    const finishedPlaying = () => {
        lastPlayed.current = new Date().valueOf();
        if(ttsUrlMemory){
            lastPlayedByUrl.current = {...(lastPlayedByUrl.current||{}), [ttsUrlMemory]: lastPlayed.current};
        }

        setPlayStatus("STOPPED")

        dispatch(actions.ttsFinished())
        dispatch(actions.resetTtsUrl())
    }

    useEffect(() => {
        if(playStatus === 'STOPPED' && !ttsUrl){
            console.log(`Reseting tts url memory because no URL is present and status is stopped`)
            setTtsUrlMemory(undefined);
        }else if((playStatus === 'STOPPED' || !playStatus) && ttsUrl){
            console.log(`Setting memory ${ttsUrl}`)
            setTtsUrlMemory(ttsUrl);
        }
    }, [playStatus, ttsUrl]);

    useEffect(() => {
        if(audioEnabled === false && playStatus !== 'STOPPED'){
            setPlayStatus('STOPPED');
        }
    }, [audioEnabled, playStatus]);

    useInterval(() => {
        const canPlayIfLastPlayIsAfter = new Date().valueOf() - (audioMaxFrequency||60)*1000;
        const canPlayIfLastPlayOfUrlIsAfter = new Date().valueOf() - (audioWaitBeforeRepeating||60*5)*1000;
        if(ttsUrlMemory && !playStatus){
            setPlayStatus("PLAYING");
        }else if(
            ttsUrlMemory &&
            playStatus !== "PLAYING" &&
            lastPlayed.current && lastPlayed.current < canPlayIfLastPlayIsAfter &&
            (!lastPlayedByUrl.current[ttsUrlMemory] || lastPlayedByUrl.current[ttsUrlMemory] < canPlayIfLastPlayOfUrlIsAfter)
        ){
            setPlayStatus("PLAYING");
        }
    }, 500, true);

    useEffect(() => {
        dispatch(actions.setAudioState({
            audioState: playStatus
        }))
    }, [dispatch, playStatus]);

    // return null;

    return <>
        {ttsUrlMemory && playStatus !== 'STOPPED' ? <ReactSound url={ttsUrlMemory || 'about:blank'} playStatus={'PLAYING'} onFinishedPlaying={finishedPlaying} onError={finishedPlaying} /> : null}
    </>
}