import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
// import geokdbush from 'geokdbush';
// import kdbush from 'kdbush';
import './spi.scss'
import './plr.scss'
// import { actions, IdiDisplayType, IdiReducerState } from '../../reducers/idi.reducer';
// import { useDispatch, useSelector } from 'react-redux';
// import { StoppingOpeningDisplay, DoorsOpeningDisplay, StopConnectionsDisplay, DestinationDisplay, PlaceholderDisplay, StopAlertDisplay, MessageAlertDisplay } from './displays';
import { useParams } from 'react-router';
import axios from 'axios';
import { IAlert, IApiRequestPidsDeparturesResponse } from '../../reducers/anytrip-api.types';
import { useInterval } from './hooks/useInterval';
import { actions } from '../../reducers';
import SpiIndicator, {SpiScreenState} from './SpiIndicator'
import { useDispatch, useSelector } from 'react-redux';
import { SpiReducerState } from '../../reducers/spi.reducer';
import { io } from "socket.io-client";
import { useDeviceId } from './hooks/useDeviceId';
import { TtsState } from '../../reducers/tts.reducer';
import {host} from '../../utils/host';
import { getBundleName } from '../../utils/get-bundle';
import { getDeviceProps } from '../../utils/get-device-props';
import useSWR from 'swr';

// const fetcher = (url: string) => axios.get(url).then(res => res.data)
interface ScreenData {
    id: string;

    name: string;
    location: string;
  
    enabled: boolean;
    blank: boolean;
    blankText: string;
    audio: boolean;
    audioMaxFrequency: number;
    audioWaitBeforeRepeating: number;
    offlineCount: number;
    screenDiagonalInches: number;
    stopRadius: number;
  
    stopIds: string[];
    modeIds: string[];
    routeGroupIds: string[];
  
    views: {
      type: string;
      stopRadius: number;
      stopIds: string[];
      modeIds: string[];
      routeGroupIds: string[];
  
      enabled: boolean;
      displaySeconds: number;
      displaySequence: number;
    }[];
  
    createdAt: string;
    updatedAt: string;
    version: number;
}

export const RemoteSpiIndicator: React.FC = () => {
    const dispatch = useDispatch();
    const deviceId = useDeviceId()
    const [versionKey, setVersionKey] = useState<string>();
    let { screenUuid } = useParams<{screenUuid: string}>();
    // const { data: screenData, error: screenError } = useSWR(screenUuid ? `https://${host}/screens/${screenUuid}` : null, fetcher, {refreshInterval: 1000})

    const [screenData, setScreenData] = useState<ScreenData>();
    const [screenError, setScreenError] = useState<string>();

    useEffect(() => {
        const socket = io(host, {
            withCredentials: true
        });
        // client-side
        socket.on("connect", () => {
            // console.log(socket.id); // x8WIv7-mJelg7on_ALbx
            const bundleName = getBundleName();
            const deviceProps = getDeviceProps();
            socket.emit('connect_screen', {screenUuid, deviceId, bundleName, deviceProps})
        });

        socket.on('receive_screen', (payload: any) => {
            // console.log(payload)
            if(payload && payload.screen && payload.screen.id){
                setScreenData(payload.screen)
            }else{
                setScreenError('Screen is not available');
            }
        })

        socket.on('reload', () => {
            window.location.reload();
        })
        
        socket.on("disconnect", () => {
            // console.log(socket.id); // undefined
        });

        return () => {
            socket.disconnect();
        }
    }, [deviceId, screenUuid])

    useEffect(() => {
        if(screenData){
            const newVersionKey = `${screenData.id}::${screenData.version}`;
            if(newVersionKey !== versionKey){
                setVersionKey(newVersionKey);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenData]);

    useEffect(() => {
        if(screenData?.screenDiagonalInches){
            dispatch(actions.setScreenDimensions({
                screenDimensions: {diagonalInches: screenData?.screenDiagonalInches}
            }))
        }

        if(screenData?.audioMaxFrequency != null){
            dispatch(actions.setMaxFrequency({
                audioMaxFrequency: screenData?.audioMaxFrequency
            }))
        }

        if(screenData?.audioWaitBeforeRepeating != null){
            dispatch(actions.setWaitBeforeRepeating({
                audioWaitBeforeRepeating: screenData?.audioWaitBeforeRepeating
            }))
        }

        if(screenData?.audio != null){
            dispatch(actions.setAudioEnabled({
                audioEnabled: screenData?.audio
            }))
        }
    }, [dispatch, screenData])

    return <RemoteSpiIndicatorInternal key={versionKey} screenData={screenData} screenError={screenError} />
}

export const RemoteSpiIndicatorInternal: React.FC<any> = ({screenData, screenError}) => {
    const [versionKey, setVersionKey] = useState<string>();
    const [viewIndex, setViewIndex] = useState<number>();
    const [alertIndex, setAlertIndex] = useState<number>(-1);
    const [viewSwitchTs, setViewSwitchTs] = useState<{start: number, displaySeconds: number}>();

    const { rotationProperties }: SpiReducerState = useSelector(
        (state: any) => state.spi
    );

    const { audioState }: TtsState = useSelector(
        (state: any) => state.tts
    );

    useEffect(() => {
        if(screenData){
            const newVersionKey = `${screenData.id}::${screenData.version}`;
            if(newVersionKey !== versionKey){
                setVersionKey(newVersionKey);
                if(viewIndex != null){
                    setViewIndex(0);
                    setViewSwitchTs({start: moment().unix(), displaySeconds: screenData.views[0].displaySeconds})
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenData]);

    const incrementView = (initial = false) => {
        // console.log('increment view called');
        // if(viewIndex == null){return;}

        let nextScreenIndex = initial ? 0 : (viewIndex||0)+1;

        if(screenData && nextScreenIndex >= screenData.views.length){
            nextScreenIndex = 0;
        }

        // run until
        let memNextScreenIndex;
        let cnt = 0;

        while(memNextScreenIndex !== nextScreenIndex && cnt < 5){
            memNextScreenIndex = nextScreenIndex;
            cnt++;
            if(screenData.views && screenData.views[nextScreenIndex].type === 'SERVICE_ALERT' && screenState && screenState.alerts.length === 0){
                nextScreenIndex++;

                if(nextScreenIndex >= screenData.views.length){
                    nextScreenIndex = 0;
                }
            }

            if(screenData.views && screenData.views[nextScreenIndex].type === 'LANDSCAPE_ALT_SERVICES' && screenState && screenState.alternativeServices && screenState.alternativeServices.length === 0){
                nextScreenIndex++;

                if(nextScreenIndex >= screenData.views.length){
                    nextScreenIndex = 0;
                }
            }
        }

        // console.log('increment view', {
        //     nextScreenIndex,
        //     nextScreen: screenData.views[nextScreenIndex],
        //     alertsLength: screenState.alerts.length
        // })

        if(screenData.views[nextScreenIndex].type === 'SERVICE_ALERT'){
            const alertCount = (screenState?.alerts.length) || 0;
            let nextAlertIndex = alertIndex+1;
            if(nextAlertIndex >= alertCount){
                nextAlertIndex = 0;
            }
            setAlertIndex(nextAlertIndex);
        }

        if(nextScreenIndex !== viewIndex){
            setViewIndex(nextScreenIndex);
            setViewSwitchTs({start: moment().unix(), displaySeconds: screenData.views[nextScreenIndex].displaySeconds})
        }
    }

    useInterval(() => {
        if(!screenData || !screenState){return;}

        if(viewSwitchTs == null){
            incrementView(true);
            return;
        }
        
        const localEndTime = viewSwitchTs.displaySeconds;
        const spiEndTime = (rotationProperties && rotationProperties.minDuration ? rotationProperties.minDuration : -Infinity);
        const ttsEndTime = audioState === 'PLAYING' ? 60 : -Infinity;
        const endTime = viewSwitchTs.start + Math.max(localEndTime, spiEndTime, ttsEndTime);

        // console.log({rotationProperties, endTime, localEndTime, spiEndTime})
        if(moment().unix() > endTime){
            incrementView()
        }
    }, 500, true);

    const apiDeparture = useRef<any>();
    const apiAltServices = useRef<any>();

    // state management

    // const [screenState, setScreenState] = useState<SpiScreenState>();

    const getScreenState = async (regionId: string, stopId: string, altServices: boolean) => {
        const now = moment().unix();

        const state : SpiScreenState = {
            departures: [],
            services: [],
            alerts: []
        };

        let data: any;
        try{
            // const {data: d}: {data: {response: IApiRequestPidsDeparturesResponse}} = await axios.get(`http://localhost:3000/api/v3/pid/${stopId}`, {
            const {data: d}: {data: {response: IApiRequestPidsDeparturesResponse}} = await axios.get(`${host}/anytrip/api/v3/region/${regionId}/pid/${stopId}`, {
                params: {
                    modes: 'au2:sydneytrains,au2:nswtrains,au2:nswcoaches,au2:metro',
                    // depArr: 'deparr',
                    limit: screenData?.offlineCount,
                    precinctStopRadius: screenData?.stopRadius ? screenData?.stopRadius : undefined,
                    offset: '0',
                    ts: now
                }
            })

            data = d;
            apiDeparture.current = data;
        }catch(e){
            if(apiDeparture.current){
                data = apiDeparture.current;
            }
        }

        state.services = data.response.departures.map((dep: any) => (dep as any).display).filter((dep: any) => dep.expectedDepartureTime >= (now - 30) && !dep.skipped && !dep.flags.terminatingService); // await Promise.all(state.departures.slice(0, 3).map(departureItem => getDisplayableService(regionId, departureItem)));
        
        const additionalAlerts: IAlert[] = [];

        const cancelledServices = data.response.departures.slice(0, 3).map((dep: any) => (dep as any).display).filter((dep: any) => dep.expectedDepartureTime >= (now - 30) && dep.skipped)
        if(cancelledServices.length > 0){
            const now = Math.round(new Date().valueOf()/1000);
            for(const cancelledService of cancelledServices){
                const id = `pidcustom:cancel:${cancelledService._stopTimeInstancePath}`;
                const depTime = moment.unix(cancelledService.scheduledDepartureTime).format('HH:mm');
                const dueToText = cancelledService.dueTo ? ` due to ${cancelledService.dueTo}` : '';
                const message = cancelledService.cancelled ? `Today's ${depTime} service to ${cancelledService.headsignDestination} ${cancelledService.headsignWaypoint || ''} has been cancelled${dueToText}` : `Today's ${depTime} service to ${cancelledService.headsignDestination} ${cancelledService.headsignWaypoint || ''} will not stop at this station${dueToText}`;
                additionalAlerts.push({
                    "id": id,
                    // "header":"Train timetable changes from 10 January",
                    "description": message,
                    "createdTime":now,
                    "currentAtTime":now,
                    "activePeriods":[{"start":now,"end":now+60}],
                    "active":true,"url":"https://www.transportnsw.info",
                    "affects":{"trips":[],"routes":[],"stops":[],"agencies":[]},
                    "_path":`alert/${id}`
                })
            }
        }

        const transposedServices = data.response.departures.slice(0, 3).map((dep: any) => (dep as any).display).filter((dep: any) => dep.expectedDepartureTime >= (now - 30) && dep.scheduledPlatformCombinedName)
        if(transposedServices.length > 0){
            const now = Math.round(new Date().valueOf()/1000);
            for(const transposedService of transposedServices){
                const id = `pidcustom:transposed:${transposedService._stopTimeInstancePath}`;
                const depTime = moment.unix(transposedService.scheduledDepartureTime).format('HH:mm');
                const message = `Today's ${depTime} service to ${transposedService.headsignDestination} ${transposedService.headsignWaypoint || ''} will now depart from ${transposedService.scheduledPlatformCombinedName}`;
                additionalAlerts.push({
                    "id": id,
                    // "header":"Train timetable changes from 10 January",
                    "description": message,
                    "createdTime":now,
                    "currentAtTime":now,
                    "activePeriods":[{"start":now,"end":now+60}],
                    "active":true,"url":"https://www.transportnsw.info",
                    "affects":{"trips":[],"routes":[],"stops":[],"agencies":[]},
                    "_path":`alert/${id}`
                })
            }
        }

        state.alerts = [...additionalAlerts].concat(data.response.alerts||[]).filter(alert => alert.active);

        if(altServices){
            let data: any;
            try{
                // const {data: d} = await axios.get(`http://localhost:3000/api/v3/region/${regionId}/pid/${stopId}/alternativeServices`, {
                const {data: d} = await axios.get(`${host}/anytrip/api/v3/region/${regionId}/pid/${stopId}/alternativeServices`, {
                    params: {
                        modes: 'au2:buses,au2:tempbuses,au2:schoolbuses,au2:privatebuses,au2:nswcoaches,au2:privatecoaches,au2:lightrail,au2:ferries',
                        // depArr: 'deparr',
                        limit: '50',
                        offset: '0',
                        ts: now
                    }
                })

                data = d;
                apiAltServices.current = data;
            }catch(e){
                if(apiAltServices.current){
                    data = apiAltServices.current;
                }
            }

            apiAltServices.current = data;

            state.alternativeServices = (data.response.alternativeServices).filter((dep: any) => dep.expectedDepartureTime >= (now - 30));
        }

        // console.log(data.response)
        // state.precinctDepartures = (((data.response as any).precinctDepartures) as IDepartureItem[]).filter((dep: any) => dep.stopTimeInstance.departure.time >= (now - 30));
        state.precinctStops = data.response.precinctStops;

        return state;
    }

    // const updateScreenState = async () => {
    //     if(screenData?.stopIds){
    //         setScreenState(await getScreenState(
    //             'au2',
    //             screenData?.stopIds.join(','),
    //             !!screenData?.views.find((s: any) => s.type === 'LANDSCAPE_ALT_SERVICES')
    //         ));
    //     }
    // }

    const {data: screenState} = useSWR(screenData ? ['au2', screenData?.stopIds.join(','), !!screenData?.views.find((s: any) => s.type === 'LANDSCAPE_ALT_SERVICES')] : null, getScreenState, { refreshInterval: 10000 });

    // useInterval(() => {
    //     if(screenData){
    //         updateScreenState();
    //     }
    // }, 10000, true);
    
    // if(!screenData || viewIndex == null){
    //     return <div>No data</div>;
    // }

    if(screenError){
        return <div className="screen-ready" style={{background: "black"}}>
            <div>{screenError}</div>
        </div>
    }

    if(!screenData || !screenState || viewIndex == null){
        return <div className="screen-ready" style={{background: "black"}}>
            <div>Loading...</div>
        </div>
    }

    if(screenData.blank){
        return <div className="screen-ready" style={{background: "black"}}>
            <div>{screenData.blankText || 'Service information not available'}</div>
        </div>
    }

    // set(() => {

    // }, [versionKey, viewIndex]);

    const screenVariant = screenData.views[viewIndex || 0];

    if(!screenVariant){
        return <div className="screen-ready" style={{background: "black"}}>
            <div>Screen not available</div>
        </div>
    }

    const mapping: {[k: string]: string} = {
        LANDSCAPE_WAITING_ROOM: 'split-screen-landscape-1',
        LANDSCAPE_ALT_SERVICES: 'alt-services-landscape',
        LANDSCAPE_PRECINCT_DEPARTURES: 'precinct-services-landscape',
        LANDSCAPE_PRECINCT_DEPARTURES_3: 'precinct-services-landscape-3',
        LANDSCAPE_STANDARD: 'standard-landscape',
        NEXT_SERVICE_PRECINCT_LANDSCAPE: 'split-screen-landscape-0',
        SERVICE_ALERT: 'customer-service-message'
    };

    if(mapping[screenVariant.type]){
        return <SpiIndicator mode={mapping[screenVariant.type]} alertIndex={alertIndex} screenData={screenData} screenState={screenState} />
    }

    return <div>Unknown type {screenVariant.type}</div>
}

export default RemoteSpiIndicator;
