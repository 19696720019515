import moment from "moment";
import { IDepartureItem } from "../../../reducers/anytrip-api.types";

export enum DepartureTimeMode {
  DEFAULT = 'default',
  PLR = 'plr'
}

export const renderDepartureTime = (time: number, now: number, mode?: DepartureTimeMode) => {
    const secondsToGo = time - now;
  
    if(mode === DepartureTimeMode.PLR){
      if(secondsToGo < 0){
        return '';
      }else if(secondsToGo <= 60){
        return '1 min';
      }else if(secondsToGo < 60*60){
        return `${Math.floor(secondsToGo/60)} min`;
      }else{
        return moment.unix(time).format('HH:mm')
      }
    }else{
      if(secondsToGo < 0){
        return 'Now';
      }else if(secondsToGo <= 60){
        return '1 min';
      }else if(secondsToGo < 60*60){
        return `${Math.floor(secondsToGo/60)} min`;
      }else if(secondsToGo < 60*60*24){
        const hr = Math.floor(secondsToGo/(60*60));
        const min = Math.floor((secondsToGo - hr*60*60)/60);
        return `${hr}h ${min}m`;
      }else{
        const d = Math.floor(secondsToGo/(60*60*24));
        return `${d} days`;
      }
    }
}

export const departureItemToModeIcon = (departureItem: IDepartureItem, replacesModeIcon: boolean = false) => {
  let modeIcon;
  let intercityRegionalLayout = false;
  if(['au2:st:NSWTrains','au2:nt:X000','au2:nt:711'].includes(departureItem.tripInstance.trip.route.agency.id)){
      modeIcon = '/img/tfnsw/regional-train.svg'
      intercityRegionalLayout = true;
  }else if(['au2:nt:710'].includes(departureItem.tripInstance.trip.route.agency.id)){
      modeIcon = '/img/tfnsw/coach.svg'
      intercityRegionalLayout = true;
  }else if(['au2:mt:SMNW'].includes(departureItem.tripInstance.trip.route.agency.id)){
      modeIcon = '/img/tfnsw/metro.svg'
  }else if(departureItem.tripInstance.trip.route.mode === 'au2:buses' || departureItem.tripInstance.trip.route.mode === 'au2:privatebuses'){
      modeIcon = '/img/tfnsw/bus.svg';
  }else if(departureItem.tripInstance.trip.route.mode === 'au2:tempbuses'){
      modeIcon = '/img/tfnsw/bus-temp.svg';
  }else if(departureItem.tripInstance.trip.route.mode === 'au2:schoolbuses'){
      modeIcon = '/img/tfnsw/bus-school.svg';
  }

  return (!replacesModeIcon || intercityRegionalLayout) ? modeIcon : undefined;
}