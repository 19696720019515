import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './spi.scss'
import { useDispatch } from 'react-redux';
import {  SpiCustomerServiceMessage } from './components';
import { IDepartureItem, IStop, IAlert, IRouteGroup, IDestination, IPrecinctStop } from '../../reducers/anytrip-api.types';
import { useInterval } from './hooks/useInterval';
import { SpiLandscapeNextService, SpiLandscapeSplitView } from './view';
import { SpiPrecinctDeparturesViewView } from './view/SpiPrecinctDeparturesView';
import { actions } from '../../reducers';
import { SpiPrecinctDeparturesViewV2 } from './view/SpiPrecinctDeparturesViewV2';
import { SpiAlternativeServices } from './view/SpiAlternativeServices';

import {host} from '../../utils/host';

const simpleTimes = (num: number, suffix: any, key: string, future: boolean) => {
    if (key === 'mm') {
        const h = Math.floor(num / 60);

        if (h > 0) {
            const m = num - h * 60;
            return `${h}hr ${m}m`
        } else {
            return `${num} min`;
        }
    }

    return "";
}
moment.defineLocale('en-pid-timetogo', {
    parentLocale: 'en',
    relativeTime: {
        future: "%s",
        past: "%s",
        s: '1 min',
        ss: '1 min',
        m: "1 min",
        mm: simpleTimes,
        h: "",
        hh: "",
        d: "",
        dd: "",
        M: "",
        MM: "",
        y: "",
        yy: ""
    }
});

moment.relativeTimeThreshold('s', 1);
moment.relativeTimeThreshold('ss', 1);
moment.relativeTimeThreshold('m', 60);
moment.relativeTimeThreshold('h', 24);
moment.relativeTimeThreshold('d', 31);
moment.relativeTimeThreshold('M', 12);

interface SpiIndicatorProps {
    mode?: string;
    screenState: SpiScreenState;
    screenData: any;
    alertIndex: number;
}

export interface SpiStoppingAt {
    displayName: string;
    displaySubName?: string;
    time?: number;
    airport?: boolean;
    passing?: boolean;
    cars?: string;
    mappedStop?: {
        routes: IRouteGroup[];
        stop: IStop
    }
}

export interface SpiService {
    _path?: string;
    _stopTimeInstancePath: string;
    stopId?: string;
    scheduledDepartureTime: number;
    expectedDepartureTime: number;

    cancelled: boolean;
    skipped: boolean;

    headsignDestination: string;
    headsignWaypoint?: string | null;

    platformNumber?: string;
    platformType?: string;
    platformCombinedName?: string;
    stationName?: string;

    scheduledPlatformCombinedName?: string;

    stoppingAt: SpiStoppingAt[];

    serviceType: string;
    carriages?: string;
    occupancy?: number[];

    modeIcon?: string;
    intercityRegionalLayout: boolean;
    isBookingRequired: boolean;

    routeName: string;
    routeColor: string;
    routeTextColor: string;

    dueTo?: string;

    dva?: string;
}

export interface SpiAlternativeServices {
    stop: IStop;
    precinctRoutes: IRouteGroup[];
    // nearbyRoutes: IRouteGroup[];
    nearbyDestinations: IDestination[]
}

export interface SpiScreenState {
    departures: IDepartureItem[]
    services: SpiService[]
    alerts: IAlert[]
    alternativeServices?: SpiService[]
    precinctDepartures?: IDepartureItem[]
    precinctStops?: IPrecinctStop[]
}

export const SpiIndicator: React.FC<SpiIndicatorProps> = ({ mode, screenState, screenData, alertIndex }) => {
    const dispatch = useDispatch();

    const [screenDisplay, setScreenDisplay] = useState(1);

    useInterval(() => {
        if (screenDisplay) {
            setScreenDisplay(0);
        } else {
            setScreenDisplay(1);
        }
    }, 10000);

    const nextDepartureStiPath = screenState?.services[0]?._stopTimeInstancePath;

    useEffect(() => {
        let nextTripInstance;
        if (screenData.audio && screenState.alerts && screenState.alerts[0] && mode === 'customer-service-message') {
            const alert = (screenState.alerts[alertIndex] || screenState.alerts[0]);
            const alertId = alert.id;
            const text = [alert.header || '', alert.description || ''].join(' ').trim();

            let ttsUrl;
            if(alertId.startsWith('pidcustom:')){
                ttsUrl = `${host}/dva/audio?customText=${encodeURIComponent(text)}`;
            }else{
                ttsUrl = `${host}/dva/audio?alertId=${encodeURIComponent(alertId)}`;
            }
            dispatch(actions.setTtsUrl({
                ttsUrl
            }))
        }else if (screenData.audio && nextDepartureStiPath && (mode === 'standard-landscape' || mode === 'split-screen-landscape-1')) {
            nextTripInstance = nextDepartureStiPath
            dispatch(actions.setTtsUrl({
                ttsUrl: `${host}/dva/audio?stopTimeInstancePath=${encodeURIComponent(nextTripInstance)}`
            }))
        }else{
            dispatch(actions.setTtsUrl({
                ttsUrl: undefined
            }))
        }

        // if(nextTripInstance !== lastServiceTripInstance){
        //     setLastServiceTripInstance(nextTripInstance);

        //     if(screenState.services && screenState.services[0] && screenState.services[0].dva){
        //         dispatch(actions.setTtsUrl({
        //             ttsUrl: `https://sandpit.anytrip.com.au/v1/tts?strings=${encodeURIComponent(screenState.services[0].dva)}`
        //         }))
        //     }
        // }
    }, [screenData.audio, nextDepartureStiPath, mode, screenState.alerts, dispatch, alertIndex])

    if (mode === "dual-screen-landscape") {
        return <div className="spi spi-dual-screen">
            <div className="spi-landscape">
                {screenState?.services?.length > 1 && <>
                    <SpiLandscapeNextService service={screenState.services[0]} />
                </>}
            </div>
            <div className="spi-landscape">
                {/* {screenState?.services?.length > 2 && 
                    <SpiLandscapeSplitView services={screenState.services} />
                } */}
                {/* {screenState.alternativeServices && <SpiLandscapeSplitView services={screenState.alternativeServices} />} */}
                {screenDisplay === 1 && screenState?.alerts?.length > 0 ?
                    <SpiCustomerServiceMessage alert={screenState?.alerts[0]} /> : (
                        screenState.precinctDepartures && screenState.precinctDepartures.length > 0 ?
                            <SpiPrecinctDeparturesViewView precinctDepartures={screenState.precinctDepartures} /> :
                            (screenState.services.length > 1 ? <SpiLandscapeSplitView services={screenState.services.slice(1, 2)} /> : null)
                    )
                }

            </div>
        </div>
    }

    if (mode === "split-screen-landscape") {
        return <div className="spi spi-single-screen">
            <div className="spi-landscape">
                {screenState?.services?.length > 1 &&
                    <SpiLandscapeSplitView services={screenState.services} precinctDepartures={screenState.precinctDepartures} />
                }
            </div>
        </div>
    }

    if (mode === "split-screen-landscape-0") {
        return <div className="spi spi-single-screen">
            <div className="spi-landscape">
                {screenState?.services?.length > 1 &&
                    <SpiLandscapeSplitView services={screenState.services} precinctDepartures={screenState.precinctDepartures} overrideScreenNumber={0} />
                }
            </div>
        </div>
    }

    if (mode === "split-screen-landscape-1") {
        return <div className="spi spi-single-screen">
            <div className="spi-landscape">
                {screenState?.services?.length > 1 &&
                    <SpiLandscapeSplitView services={screenState.services} precinctDepartures={screenState.precinctDepartures} overrideScreenNumber={1} />
                }
            </div>
        </div>
    }

    if (mode === "alt-services-landscape") {
        return <div className="spi spi-single-screen">
            <div className="spi-landscape">
                {screenState.alternativeServices ?
                    <SpiAlternativeServices alternativeServices={screenState.alternativeServices} /> : null
                }
            </div>
        </div>
    }

    if (mode === "precinct-services-landscape") {
        return <div className="spi spi-single-screen">
            <div className="spi-landscape">
                {screenState.precinctStops && screenState.precinctStops.length > 0 ?
                    <SpiPrecinctDeparturesViewV2 precinctStops={screenState.precinctStops} colsPerPage={2} durationPerPage={15} /> : null
                }
            </div>
        </div>
    }

    if (mode === "precinct-services-landscape-3") {
        return <div className="spi spi-single-screen">
            <div className="spi-landscape">
                {screenState.precinctStops && screenState.precinctStops.length > 0 ?
                    <SpiPrecinctDeparturesViewV2 precinctStops={screenState.precinctStops} colsPerPage={3} durationPerPage={15} /> : null
                }
            </div>
        </div>
    }

    if (mode === 'customer-service-message' && screenState.alerts.length > 0) {
        return <div className="spi spi-single-screen">
            <div className="spi-landscape">
                <SpiCustomerServiceMessage alert={screenState.alerts[alertIndex] || screenState.alerts[0]} />
            </div>
        </div>
    }else if (mode === 'customer-service-message' && screenState.alerts.length === 0) {
        return <div>No alerts</div>
    }

    return <div className="spi spi-single-screen">
        <div className="spi-landscape">
            {screenState?.services?.length > 0 &&
                <SpiLandscapeNextService service={screenState.services[0]} />}
        </div>
    </div>
}

export default SpiIndicator;
