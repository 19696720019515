import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../../../reducers';
import { IAlert } from '../../../reducers/anytrip-api.types';

interface SpiCustomerServiceMessageProps {
    alert: Pick<IAlert, 'description'|'header'>;
    // children: React.ReactChild;
    // style?: React.CSSProperties;
}

const cleanLine = (line: string) => {
    return line.replace(/[\s.]+$/, '').replace(/^[\s.]+/, '').replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
}

const alertTransformer = (alert: Pick<IAlert, 'description'|'header'>) => {
    const paras = [];

    if(alert.header){
        paras.push(cleanLine(alert.header));
    }

    if(alert.description){
        const desc = alert.description.split(/[\n\r]+/);
        for(const line of desc){
            const cl = cleanLine(line);
            if(cl !== paras[0]){
                paras.push(cl)
            }
        }
    }

    return paras.map((line, i) => <p key={i}>{line.split('').map((s, ii) => <span key={ii} className={'dt'}>{s}</span>)}</p>)

}
export const SpiCustomerServiceMessage: React.FC<SpiCustomerServiceMessageProps> = ({alert}) => {
    const [secondsToScroll, setSecondsToScroll] = useState(0);
    const scrollingContainerEl : React.MutableRefObject<HTMLDivElement|null> = useRef(null);

    const dispatch = useDispatch();

    const listener = () => {
        if(scrollingContainerEl && scrollingContainerEl.current){
            const height = scrollingContainerEl.current.clientHeight;
            // console.log({height});
            // const spans = scrollingContainerEl.current.querySelectorAll('span.dt');
            // console.log({height, spans});
            const stopEl = scrollingContainerEl.current.querySelector('.spi-csm-body-content');
            const textHeight = (stopEl ? stopEl.clientHeight : 0);

            if(textHeight > height){
                const sts = ((textHeight + 200)/112) * 2.5;
                if(sts !== secondsToScroll){
                    setSecondsToScroll(sts);
                }
            }else{
                setSecondsToScroll(0)
            }

            // // stationsToScroll * 
            // console.log({height, textHeight, secondsToScroll});
        }
    };

    useEffect(() => {
        if(secondsToScroll > 0){
            dispatch(actions.setRotationProperties({
                rotationProperties: {
                    minDuration: secondsToScroll + 5
                }
            }))
        }else{
            dispatch(actions.setRotationProperties({}))
        }
    }, [dispatch, secondsToScroll]);

    useEffect(() => {
        window.addEventListener('resize', listener);

        return () => {
            window.removeEventListener('resize', listener);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        listener();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollingContainerEl, alert.description, alert.header]);

    const transformedAlert = useMemo(() => {
        return alertTransformer(alert)
    }, [alert])

    return <div className="spi-csm">
        <div className="spi-csm-wrapper">
            <div className="spi-csm-header">
                <h1>Service message</h1>
            </div>
            <div className="spi-csm-abs" ref={scrollingContainerEl}>
                {/* <div className="spi-csm-body">
                    <div className="spi-csm-body-content">{alertTransformer(alert)}</div>
                </div> */}
                {alert && <div className="spi-csm-body" style={secondsToScroll ? {
                    animationDelay: (secondsToScroll > 0 ? '5000ms' : '0ms'),
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'linear',
                    animationDuration: `${secondsToScroll*1000}ms`,
                    animationName: 'spi-stopping-pattern-scroll'
                } : {}} key={secondsToScroll}>
                    <div className="spi-csm-body-content">{transformedAlert}</div>
                    {secondsToScroll ? <div className="spi-csm-body-content">{transformedAlert}</div> : null}
                </div>}
            </div>
            <div className="spi-csm-tni">
                Visit <strong>transportnsw.info</strong> for service updates or to plan your trip.
            </div>
        </div>
    </div>
}