import { useMemo } from "react"
import { v4 as uuidv4 } from 'uuid';

export const useDeviceId = () => {
    const deviceId = useMemo(() => {
        let deviceId;
        try {
            if(window.localStorage.getItem('deviceId')){
                deviceId = window.localStorage.getItem('deviceId')
            }
        }catch(e){
            // there was an error
            console.error('Could not find or load device ID')
        }

        if(!deviceId){
            // create a device ID
            deviceId = uuidv4();

            try {
                window.localStorage.setItem('deviceId', deviceId);
            }catch(e){
                // there was an error
                console.error('Could not save device ID')
            }
        }

        return deviceId
    }, [])

    return deviceId;
}