import moment from 'moment';
import React from 'react';
import { DepartureTimeMode, renderDepartureTime } from '../common/utils';
import { useAs1428 } from '../hooks/useAs1428';
import { SquareDepartureBadge } from './SquareDepartureBadge';

// const dummy = [
//     {routeNumber: '0000', destination: 'Macquaire Park', departureTime: 0},
//     {routeNumber: '550', destination: 'Macquaire Park', departureTime: 0},
//     {routeNumber: '550', destination: 'Macquaire Park', departureTime: 0},
//     {routeNumber: '550', destination: 'Macquaire Park', departureTime: 0},
//     {routeNumber: '550', destination: 'Macquaire Park', departureTime: 0}
// ]

const acceptedRoutes = [
    'T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8', 'T9',
    'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9',
    'L1', 'L2', 'L3', 'L4', 'LX',
    'BMT', 'SCO', 'SHL', 'HUN', 'CCN'
]

const modeIconRoutes = ['M', 'NLR', 'NR'];

interface PlrDepartureItemsProps {
    departures: {routeNumber: string; routeColor: string; routeModeIcon: string; destination: {headline: string, subtitle?: string}; departureTime: number}[]
}
export const PlrDepartureItems: React.FC<PlrDepartureItemsProps> = ({departures}) => {
    const {getDimension} = useAs1428(55);
    return <div className="plr-departure-list-wrapper" style={{padding: getDimension(25), paddingTop: 0, paddingBottom: getDimension(35), minWidth: 0}}>
        <div className="plr-departure-list-header" style={{
            height: getDimension(45), marginBottom: getDimension(15),
            lineHeight: getDimension(20, 'px'),
            fontSize: getDimension(27),
        }}>
            <div className="plr-departure-list-header-next-service" style={{
                marginLeft: getDimension(25)
            }}>Next service</div>
            <div className="plr-departure-list-header-departs" style={{
                marginRight: getDimension(25)
            }}>Departs</div>
        </div>
        {(departures).map((v, i) => <div className="plr-departure-list-item" key={i} style={{
            height: getDimension(85),
            background: i % 2 === 0 ? '#d1d3d4' : undefined,
            paddingLeft: getDimension(25),
            paddingRight: getDimension(25),
            lineHeight: getDimension(40, 'px'),
            fontSize: getDimension(55), minWidth: 0
        }}>
            <div className="plr-departure-list-item-route" style={{
                width: getDimension(135),
                paddingRight: getDimension(25),
            }}>
                {/* {v.routeNumber} */}
                {/* <img src={"/svg/T9.svg"} style={{width: getDimension(70), height: getDimension(70)}} /> */}
                {modeIconRoutes.includes(v.routeNumber) ? <img src={v.routeModeIcon} alt="Mode icon" style={{width: getDimension(70), height: getDimension(70)}} /> : acceptedRoutes.includes(v.routeNumber) ? <SquareDepartureBadge size={getDimension(70)} text={v.routeNumber} color={v.routeColor} /> : v.routeNumber}
            </div>
            {typeof v.destination === 'object' ? <div className="plr-departure-list-item-destination">{v.destination.headline} {v.destination.subtitle}</div> : <div className="plr-departure-list-item-destination">{v.destination}</div>}
            <div className="plr-departure-list-item-time" style={{paddingLeft: getDimension(25)}}>{renderDepartureTime(
                    v.departureTime,
                    moment().unix(),
                    DepartureTimeMode.PLR
                  )}</div>
        </div>)}
    </div>
}