import moment from 'moment';
import React from 'react';
import { DepartureTimeMode, renderDepartureTime } from '../common/utils';
import { useAs1428 } from '../hooks/useAs1428';
interface PlrAlternativeServicesDestinationsProps {
    // departures: {routeNumber: string; routeColor: string; routeModeIcon: string; destination: {headline: string, subtitle?: string}; departureTime: number}[]
    destinationGroups: any[]
}
export const PlrAlternativeServicesDestinations: React.FC<PlrAlternativeServicesDestinationsProps> = ({destinationGroups}) => {
    const {getDimension} = useAs1428(55);
    return <div className="plr-departure-list-wrapper" style={{padding: getDimension(25), paddingTop: 0, paddingBottom: getDimension(35), minWidth: 0}}>
        <div className="plr-departure-list-header" style={{
            height: getDimension(45), marginBottom: getDimension(15),
            lineHeight: getDimension(20, 'px'),
            fontSize: getDimension(27),
            paddingLeft: getDimension(25),
            paddingRight: getDimension(25)
        }}>
            <div className="" style={{
                width: getDimension(600)
            }}>Destination</div>
            <div className="" style={{
                marginLeft: getDimension(25), flexGrow: 1
            }}>Next service</div>
            <div className="" style={{
                marginLeft: getDimension(25), flexGrow: 1
            }}>Departs from</div>
            <div className="plr-departure-list-header-departs" style={{
                marginLeft: getDimension(25), width: getDimension(220)
            }}>Departs</div>
        </div>
        {destinationGroups.length === 0 && [0,1,2,3,4].map((_, i) => <div key={i} className="plr-departure-list-item" style={{
            // height: getDimension(85),
            background: i % 2 === 0 ? '#d1d3d4' : undefined,
            padding: getDimension(25),
            lineHeight: getDimension(70, 'px'),
            fontSize: getDimension(55), minWidth: 0
        }}>&nbsp;</div>)}
        {destinationGroups.map((destinationGroup, i) => <div key={i} className="plr-departure-list-item" style={{
            // height: getDimension(85),
            background: i % 2 === 0 ? '#d1d3d4' : undefined,
            padding: getDimension(25),
            lineHeight: getDimension(70, 'px'),
            fontSize: getDimension(55), minWidth: 0
        }}>
            <div className="plr-departure-alt-destinations" style={{
                width: getDimension(600), flexShrink: 0,
            }}>
                {destinationGroup.destinationNames.map((s: string, i: number) => <div key={i}>{s}</div>)}
            </div>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                {destinationGroup.stops.map((d: any, i: number) => <div key={i} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: i === 0 ? 0 : getDimension(25)}}>
                    <div className="plr-departure-alt-destinations" style={{
                        marginLeft: getDimension(25),
                        flex: 1
                    }}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <img src={"/svg/walk.svg"} alt="Mode icon" style={{width: getDimension(70), height: getDimension(70)}} />
                            <div style={{marginLeft: getDimension(25)}}>▶</div>
                            <img src={d.modeIcon || "/img/tfnsw/bus-temp.svg"} alt="Mode icon" style={{width: getDimension(70), height: getDimension(70), marginLeft: getDimension(25)}} />
                            <div>
                                {d.routes.map((r: string, i: number) => <div key={i} style={{minWidth: getDimension(60), display: 'inline-block', paddingLeft: getDimension(25), paddingRight: getDimension(25), borderRadius: getDimension(10), color: 'white', backgroundColor: "#00B5EF"}}>{r}</div>)}
                            </div>
                        </div>
                        <div className="plr-departure-alt-departing-stop-number" style={{
                            lineHeight: getDimension(55, 'px'),
                            fontSize: getDimension(40),
                        }}>{d.departure.walkTime <= 1 ? '<1 min' : `${d.departure.walkTime} min`}</div>
                    </div>
                    <div className="plr-departure-alt-destinations" style={{
                        marginLeft: getDimension(25),
                        flex: 1,
                        minWidth: 0
                    }}>
                        <div className="plr-departure-alt-departing-stop-name">{d.departure.stopName}</div>
                        <div className="plr-departure-alt-departing-stop-number" style={{
                            lineHeight: getDimension(55, 'px'),
                            fontSize: getDimension(40),
                        }}>{d.departure.stopCode}</div>
                    </div>
                    <div className="plr-departure-list-item-time" style={{marginLeft: getDimension(25), width: getDimension(220)}}>{renderDepartureTime(
                        d.departure.expectedDepartureTime,
                        moment().unix(),
                        DepartureTimeMode.PLR
                    )}</div>
                </div>)}
            </div>
        </div>)}
    </div>
}