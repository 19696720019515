import axios from 'axios';
import { takeEvery, select, delay, put, race, take, all } from 'redux-saga/effects';
import { actionTypes, actions } from '../reducers';
import { IApiRequestTripInstanceResponse, IRealtimeStopTime } from '../reducers/anytrip-api.types';
import { ScreenModes, IdiDisplayType, IdiDisplay, IdiReducerState } from '../reducers/idi.reducer';
import moment from 'moment';
import get from 'lodash/get';
import { getInterchangeModes } from '../utils';

// DOORS_OPENING,
// DESTINATION,
// STOPPING_PATTERN,
// STOP_CONNECTIONS,
// STOP_ALERT,
// STOPPAGE,
// MESSAGE,
// CAPACITY,
// FACILITIES_A,
// FACILITIES_B,
// MULTIMEDIA,
// ANNOUNCEMENT

// const STATE_CONTROL = {
//   [TripState.AT_STOP]: {
//     rules: [
//       {
//         conditions: {
//           fact: 
//         }
//       }
//     ]
//   }
// }

const DISPLAYS_BY_SCREEN_MODE : {[k: string]: IdiDisplay[]} = {
  [ScreenModes.BLANK]: [
    {displayType: IdiDisplayType.BLANK}
  ],
  [ScreenModes.AT_STOP]: [
    {displayType: IdiDisplayType.DOORS_OPENING}
  ],
  [ScreenModes.DEPARTURE]: [
    {displayType: IdiDisplayType.STOP_CONNECTIONS, minDisplaySeconds: 30},
    {displayType: IdiDisplayType.DESTINATION, minDisplaySeconds: 10},
    {displayType: IdiDisplayType.STOPPING_PATTERN, minDisplaySeconds: 10}
  ],
  [ScreenModes.EN_ROUTE_A]: [
    {displayType: IdiDisplayType.STOP_CONNECTIONS, minDisplaySeconds: 30},
    {displayType: IdiDisplayType.STOP_ALERT, minDisplaySeconds: 2},
    {displayType: IdiDisplayType.MESSAGE, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.CAPACITY, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.FACILITIES_A, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.FACILITIES_B, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.MULTIMEDIA, minDisplaySeconds: 4},
    {displayType: IdiDisplayType.DESTINATION, minDisplaySeconds: 10},
    {displayType: IdiDisplayType.STOPPING_PATTERN, minDisplaySeconds: 10}
  ],
  [ScreenModes.MID_ROUTE]: [
    {displayType: IdiDisplayType.STOP_CONNECTIONS, minDisplaySeconds: 30},
    {displayType: IdiDisplayType.STOP_ALERT, minDisplaySeconds: 2},
    {displayType: IdiDisplayType.MESSAGE, minDisplaySeconds: 2}
  ],
  [ScreenModes.MID_ROUTE_NON_HIJACK]: [
    {displayType: IdiDisplayType.STOP_CONNECTIONS, minDisplaySeconds: 30},
    {displayType: IdiDisplayType.STOP_ALERT, minDisplaySeconds: 2},
    {displayType: IdiDisplayType.MESSAGE, minDisplaySeconds: 2}
  ],
  [ScreenModes.EN_ROUTE_B]: [
    {displayType: IdiDisplayType.STOP_CONNECTIONS, minDisplaySeconds: 30},
    {displayType: IdiDisplayType.STOP_ALERT, minDisplaySeconds: 2},
    {displayType: IdiDisplayType.MESSAGE, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.CAPACITY, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.FACILITIES_A, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.FACILITIES_B, minDisplaySeconds: 4},
    // {displayType: IdiDisplayType.MULTIMEDIA, minDisplaySeconds: 4},
    {displayType: IdiDisplayType.DESTINATION, minDisplaySeconds: 10},
    {displayType: IdiDisplayType.STOPPING_PATTERN, minDisplaySeconds: 10}
  ],
  [ScreenModes.APPROACH]: [
    {displayType: IdiDisplayType.STOP_CONNECTIONS, minDisplaySeconds: 30},
    {displayType: IdiDisplayType.STOP_ALERT, minDisplaySeconds: 10},
  ],
  [ScreenModes.APPROACH_REPEAT]: [
    {displayType: IdiDisplayType.STOP_CONNECTIONS, minDisplaySeconds: 30},
    {displayType: IdiDisplayType.STOP_ALERT, minDisplaySeconds: 10},
  ],
}

function* watchScreenState(){
  const {nextScreenState, prevScreenState} = yield getNextScreenState();
  const canHijack = isScreenStateHijackable(get(nextScreenState, 'mode'));

  if(
    get(prevScreenState, 'mode') !== get(nextScreenState, 'mode') ||
    get(prevScreenState, 'surroundingStops.at.stop.id') !== get(nextScreenState, 'surroundingStops.at.stop.id') ||
    get(prevScreenState, 'surroundingStops.next.stop.id') !== get(nextScreenState, 'surroundingStops.next.stop.id') ||
    get(prevScreenState, 'surroundingStops.prev.stop.id') !== get(nextScreenState, 'surroundingStops.prev.stop.id')
  ){
    if(canHijack && prevScreenState){
      yield put(actions.displayCancel());
      yield put(actions.screenModeCancel());
    }

    if(prevScreenState){
      const {cancel} = yield race({
        screenEnd: take(actionTypes.SCREEN_MODE_END),
        cancel: take(actionTypes.CHECK_SCREEN_STATE)
      });

      if(cancel){return;}
    }

    yield put(actions.receiveScreenState({screenState: nextScreenState, resetDisplay: true}));
    yield put(actions.screenModeStart());
  }else{
    // update state
    yield put(actions.receiveScreenState({screenState: nextScreenState}));

    // wait for screen end or wait for next check screen state to come along
    const {cancel} = yield race({
      screenEnd: take(actionTypes.SCREEN_MODE_END),
      cancel: take(actionTypes.CHECK_SCREEN_STATE)
    });

    // if cancelled, don't progress
    if(cancel){return;}

    // if not cancelled, this means screen mode end was reached
    // in this case, reset display to start from beginning
    yield put(actions.receiveScreenState({screenState: nextScreenState, resetDisplay: true}));
    yield put(actions.screenModeStart());
  }
}

function* orchestrateScreenMode(){
  const {screenState} = yield select(state => state.idi);

  const displays = DISPLAYS_BY_SCREEN_MODE[screenState.mode];
  for(let i = 0; i < displays.length; i++){
    yield put(actions.displayStart({
      currentDisplay: displays[i],
      currentDisplayIndex: i
    }))

    const {cancel} = yield race({
      display: take(actionTypes.DISPLAY_END),
      cancel: take(actionTypes.SCREEN_MODE_CANCEL)
    });

    if(cancel){
      break;
    }
  }
  yield put(actions.screenModeEnd())
}

function *getNextScreenState(){
  // work out where the trip is
  const {screenState, tripInstanceData} = yield select(state => state.idi);
  if(!tripInstanceData){return {}}
  const {tripInstance, realtimePattern, vehicle, rel, alerts} : IApiRequestTripInstanceResponse = tripInstanceData;
  const prevScreenMode = (screenState ? screenState.mode : null)

  let nextScreenMode: ScreenModes = ScreenModes.BLANK;

  const revenueStops : IRealtimeStopTime[] = (realtimePattern||[]).filter(p => (p.pickUp !== 1 || p.dropOff !== 1) && !p.skipped).concat(tripInstance.tripContinues && rel.next && rel.next.realtimePattern ? rel.next.realtimePattern.filter(p => (p.pickUp !== 1 || p.dropOff !== 1) && !p.skipped && !(p.firstStop && p.dropOff === 1 && tripInstance.tripContinues)) : []);

  let prevStop = undefined;
  let atStop = undefined;
  let nextStop = undefined;
  // let prevI: number|undefined = undefined;
  // let atI: number|undefined = undefined;
  let nextI: number|undefined = undefined;
  let progress = undefined;
  let transitTime = undefined;
  // let timeToNextStop = undefined;

  const time = moment().unix();

  

  if(vehicle && vehicle.lastPosition && vehicle.lastPosition.vdap != null){
    const {vdap} = vehicle.lastPosition;
    let tol = 150;

    for(let i = 0; i < revenueStops.length; i++){
      // let d = Math.abs(slds[i].getShapeDistance() - vdap);
      const stopTime = revenueStops[i];
      const d = Math.abs(stopTime.shapeDistance - vdap);

      if(stopTime.shapeDistance < vdap && d > tol){
          // prevI = i
          prevStop = stopTime;
      }else if(d <= tol){
          // atI = i
          atStop = stopTime;
      }else if(stopTime.shapeDistance > vdap && d > tol && nextI === undefined){
          nextI = i
          nextStop = stopTime;
      }
    }

    // calculate progress
    if(atStop !== undefined){progress = 0;}
    else if(nextStop !== undefined && prevStop !== undefined){
      progress = Math.max(0, (vdap - prevStop.shapeDistance)/(nextStop.shapeDistance-prevStop.shapeDistance));
      transitTime = Math.max(0, nextStop.arrival.time - prevStop.departure.time);
      // distanceRemaining = nextStop.shapeDistance - vdap;
    }
  }else{
    for(let i = 0; i < revenueStops.length; i++){
      const stopTime = revenueStops[i];
      const arrivalTime = stopTime.arrival.time;
      const departureTime = stopTime.departure.time + 15;

      if(departureTime < time){
          // prevI = i
          prevStop = stopTime;
      }else if(arrivalTime <= time && departureTime >= time){
          // atI = i
          atStop = stopTime;
      }else if(arrivalTime > time && nextI === undefined){
          nextI = i
          nextStop = stopTime;
      }
    }

    // calculate progress
    if(atStop !== undefined){progress = 0;}
    else if(nextStop !== undefined && prevStop !== undefined){
      progress = Math.max(0, (time - prevStop.departure.time)/(nextStop.arrival.time-prevStop.departure.time));
      transitTime = Math.max(0, nextStop.arrival.time - prevStop.departure.time);
      // timeToNextStop = nextStop.arrival.time - time;
    }
  }

  const decide = (map: any, def: ScreenModes) => {
    if(!prevScreenMode){
      return def;
    }

    return map[prevScreenMode] || def;
  }

  if(atStop !== undefined){
    nextScreenMode = ScreenModes.AT_STOP;
    console.log(`Time until departure: ${atStop.departure.time - time}`)
  }else if(progress !== undefined){
    if(transitTime === undefined || transitTime < 3*60){
      // immediately show mid route sequence
      if(progress < 0.77){
        nextScreenMode = decide({
          [ScreenModes.DEPARTURE]: ScreenModes.MID_ROUTE_NON_HIJACK,
          [ScreenModes.MID_ROUTE_NON_HIJACK]: ScreenModes.EN_ROUTE_B,
          [ScreenModes.EN_ROUTE_B]: ScreenModes.EN_ROUTE_B
        }, ScreenModes.DEPARTURE);
      }else{
        nextScreenMode = decide({
          [ScreenModes.APPROACH_REPEAT]: ScreenModes.APPROACH_REPEAT,
          [ScreenModes.APPROACH]: ScreenModes.APPROACH_REPEAT
        }, ScreenModes.APPROACH);
      }
    }else{
      if(progress < 0.50){
        nextScreenMode = decide({
          [ScreenModes.DEPARTURE]: ScreenModes.EN_ROUTE_A,
          [ScreenModes.EN_ROUTE_A]: ScreenModes.EN_ROUTE_A
        }, ScreenModes.DEPARTURE);
      }else if(progress < 0.77){
        nextScreenMode = decide({
          [ScreenModes.MID_ROUTE]: ScreenModes.EN_ROUTE_B,
          [ScreenModes.EN_ROUTE_B]: ScreenModes.EN_ROUTE_B
        }, ScreenModes.MID_ROUTE);
      }else{
        nextScreenMode = decide({
          [ScreenModes.APPROACH_REPEAT]: ScreenModes.APPROACH_REPEAT,
          [ScreenModes.APPROACH]: ScreenModes.APPROACH_REPEAT
        }, ScreenModes.APPROACH);
      }
    }
  }

  const filterAfterIndex = (nextI != null ? nextI : 0);
  const remainingStoppingPattern = revenueStops.filter((v, i) => i >= filterAfterIndex);

  let messageAlerts = (alerts||[]).filter(alert => alert.active).map(alert => {
    return {
      icon: 'alert',
      title: alert.header,
      body: alert.description
    }
  });
  let nextStopAlerts = [];
  let nextStopModes = [];
  let nextStopDepartures: any[] = [];
  if(nextStop){
    const parentOrReference = nextStop.stop.parent || nextStop.stop || {};
    let shortPlatformInterchangeModes = getInterchangeModes(parentOrReference.id);
    nextStopDepartures = yield getCachedInterchangeDepartures(parentOrReference.id, nextStop.arrival.time);

    if(shortPlatformInterchangeModes){
      nextStopModes = shortPlatformInterchangeModes.modes;
      
      if(shortPlatformInterchangeModes.shortPlatform){
        nextStopAlerts.push({
          icon: 'alert',
          title: 'Short platform',
          body: shortPlatformInterchangeModes.shortPlatform
        })
      }
    }
  }

  const nextScreenState = {
    mode: nextScreenMode,
    surroundingStops: {
      at: atStop,
      next: nextStop,
      prev: prevStop
    },
    tripInstance,
    remainingStoppingPattern,
    scrollDurationSeconds: Math.max(0, ((remainingStoppingPattern.length-7) * 1.25)),
    nextStopAlerts,
    nextStopModes,
    nextStopDepartures,
    messageAlerts
  }

  return {nextScreenState, nextScreenMode, prevScreenState: screenState, prevScreenMode, progress}
}

function isScreenStateHijackable(mode: ScreenModes){
  return [ScreenModes.DEPARTURE, ScreenModes.APPROACH, ScreenModes.AT_STOP, ScreenModes.MID_ROUTE, ScreenModes.BLANK].includes(mode)
}

function* calculateDisplaySeconds(){
  const {screenState, currentDisplay} = yield select(state => state.idi);
  let displaySeconds = currentDisplay.minDisplaySeconds;

  if(currentDisplay.displayType === IdiDisplayType.STOPPING_PATTERN){
    displaySeconds = screenState.scrollDurationSeconds + 15;
  }

  if(currentDisplay.displayType === IdiDisplayType.STOP_ALERT){
    displaySeconds = screenState.nextStopAlerts.length * 10;
  }

  if(currentDisplay.displayType === IdiDisplayType.MESSAGE){
    displaySeconds = screenState.messageAlerts.length * 10;
  }

  return displaySeconds;
}

function* orchestrateDisplay(){

  const {currentDisplay} = yield select(state => state.idi);
  let displaySeconds = yield calculateDisplaySeconds();

  const start = Date.now();
  console.log(`Scheduled display ${currentDisplay.displayType} to show for ${displaySeconds} seconds`);

  if(displaySeconds != null){
    const {cancel} = yield race({
      displayAndAnnounce: all([
        delay(displaySeconds*1000),
        take(actionTypes.ANNOUNCE_END)
      ]),
      cancel: take(actionTypes.DISPLAY_CANCEL),
    })

    console.log(`Display ${currentDisplay.displayType} ended after ${Date.now()-start}ms`);

    if(cancel){
      console.log(`Display ${currentDisplay.displayType} cancelled`)
    }

    yield put(actions.displayEnd());
  }else{
    yield take(actionTypes.DISPLAY_CANCEL);
    yield put(actions.displayEnd());
  }
}

function* getCachedInterchangeDepartures(stopId: string, departureTime: number){
  const {selectedTripInstance, interchangeDeparturesData} = (yield select(state => state.idi)) as IdiReducerState;

  if(!selectedTripInstance){return []}

  console.log(interchangeDeparturesData)
  
  if(interchangeDeparturesData){
    const expireTime = moment().subtract(15, 's').unix()

    // perform check
    if(interchangeDeparturesData.header.timestamp >= expireTime && interchangeDeparturesData.response.stop.id === stopId){
      return interchangeDeparturesData.response.departures;
    }
  }

  const {data} = yield axios.get(`https://anytrip.com.au/api/v3/region/${selectedTripInstance.region || 'au2'}/departures/${stopId}`, {params: {
    offset: 0,
    limit: 50,
    ts: departureTime
    // modes: ['au2:nswcoaches', 'au2:buses'].join(',')
  }});
  yield put(actions.receiveInterchangeDepartures({interchangeDeparturesData: data}));
  return data.response.departures;
}

export function* idi() {
  yield takeEvery(actionTypes.DISPLAY_START, orchestrateDisplay);
  yield takeEvery(actionTypes.SCREEN_MODE_START, orchestrateScreenMode);
  yield takeEvery(actionTypes.CHECK_SCREEN_STATE, watchScreenState);
}
