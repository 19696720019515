export const getDeviceProps = () => {
    const width = window.screen.width * window.devicePixelRatio;
    const height = window.screen.height * window.devicePixelRatio;
    const userAgent = navigator.userAgent;

    return {
        width,
        height,
        userAgent
    }
}