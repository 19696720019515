import React, { useEffect, useMemo, useState } from "react";
// import { IPrecinctStop } from "../../../reducers/anytrip-api.types";
// import { PlrModeHeader } from "../components/PlrModeHeader";
// import { PlrDepartureItems } from "../components/PlrDepartureItems";
// import { getModeIconFromString, getModeNameFromString } from "../../../utils";
import { useAs1428 } from "../hooks/useAs1428";
// import moment from "moment";
import { useDispatch } from "react-redux";
import { actions } from "../../../reducers";
import { useInterval } from "../hooks/useInterval";
import { SpiService } from "..";
import { PlrAlternativeServicesDestinations } from "../components/PlrAlternativeServiceDestinations";
import { PlrAltHeader } from "../components/PlrAltHeader";

// const modeSort = {Trains: 1, Metro: 1, Ferries: 2, 'Light Rail': 3, Coaches: 4, Buses: 5, 'Other modes': 6}
interface SpiAlternativeServicesProps {
    alternativeServices: SpiService[];
}
export const SpiAlternativeServices: React.FC<SpiAlternativeServicesProps> = ({
    alternativeServices,
}) => {
    const durationPerPage = 15;
    const {getDimension} = useAs1428(55);
    const dispatch = useDispatch();

    const pages = useMemo(() => {
        const destinations = new Map();
        const stopDepartures = new Map();
        for(const service of alternativeServices){
            for(const destination of service.stoppingAt){
                const destinationStopId = destination.mappedStop?.stop.id;
                if(!destinations.has(destinationStopId)){
                    destinations.set(destinationStopId, {
                        stopId: destinationStopId,
                        displayName: destination.displayName,
                        stopTimeInstancePaths: [],
                        departureKeys: new Set(),
                        combinedDepartureKey: ''
                    })
                }

                // const departureKey = `${service.stopId}::${service.routeName.padStart(8, '0')}::${service.routeColor}`;

                destinations.get(destinationStopId).stopTimeInstancePaths.push(service._stopTimeInstancePath);
                // destinations.get(destinationStopId).departureKeys.add(departureKey);
                destinations.get(destinationStopId).combinedDepartureKey = destinations.get(destinationStopId).stopTimeInstancePaths.join('|');
                stopDepartures.set(service._stopTimeInstancePath, service);
            }
        }

        const destinationGroups = new Map();
        destinations.forEach((destination) => {
            if(!destinationGroups.has(destination.combinedDepartureKey)){
                destinationGroups.set(destination.combinedDepartureKey, {destinations: [], destinationNames: [], stops: new Map()})
            }

            destinationGroups.get(destination.combinedDepartureKey).destinations.push(destination);
            if(!destinationGroups.get(destination.combinedDepartureKey).destinationNames.includes(destination.displayName)){
                destinationGroups.get(destination.combinedDepartureKey).destinationNames.push(destination.displayName);
            }
        })
        
        destinationGroups.forEach(group => {
            // console.log(group)
            for(const destination of group.destinations){
                for(const stiPath of destination.stopTimeInstancePaths){
                    const departure = stopDepartures.get(stiPath);
                    if(!departure){return;}

                    if(!group.stops.has(departure.stopId)){
                        group.stops.set(departure.stopId, {
                            departure,
                            routes: []
                        })
                    }

                    if(!group.stops.get(departure.stopId).routes.includes(departure.routeName)){
                        group.stops.get(departure.stopId).routes.push(departure.routeName)
                    }

                    group.stops.get(departure.stopId).modeIcon = departure.modeIcon
                }
            }

            delete group.destinations;
            group.stops = [...group.stops.values()]

            let stopHeight = (70 + 55 + 25) * group.stops.length + 25;
            let destinationHeight = (70) * group.destinationNames.length + 25 + 25;

            // console.log({stopHeight: getDimension(stopHeight), destinationHeight: getDimension(destinationHeight), dest: group})

            group.height = Math.max(stopHeight, destinationHeight);
        })

        const pages = [];
        let cumulativeGroup: any[] = [];
        let cumulativeHeight = getDimension(165+45+15);
        // console.log('header', getDimension(165+45+15))
        const maxHeight = 1080;
        for(const destinationGroup of destinationGroups.values()){
            // console.log('ch', cumulativeHeight)
            const calcHeight = getDimension(destinationGroup.height);
            if((cumulativeHeight + calcHeight) > maxHeight){
                // new page
                pages.push(cumulativeGroup)
                cumulativeGroup = [];
                cumulativeHeight = getDimension(165+45+15);
            }

            cumulativeHeight += calcHeight;
            cumulativeGroup.push(destinationGroup)
        }
        pages.push(cumulativeGroup)

        return pages;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alternativeServices]);

    useEffect(() => {
        if(pages.length > 0){
            dispatch(actions.setRotationProperties({
                rotationProperties: {
                    pages: pages.length,
                    minDuration: durationPerPage * pages.length
                }
            }))
        }else{
            dispatch(actions.setRotationProperties({}))
        }
    }, [dispatch, durationPerPage, pages]);

    const [page, setPage] = useState<number>(0)

    useInterval(() => {
        if(page >= (pages.length-1)){
            setPage(0);
        }else{
            setPage(page+1);
        }
    }, durationPerPage * 1000);

    return (
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            <PlrAltHeader currentTime title={"Alternative services to"} />
            <PlrAlternativeServicesDestinations destinationGroups={pages[page]} />
        </div>
    );
};
